import React from 'react'

class IndexPage extends React.Component {
	componentDidMount() {
		window.location.replace('/login/')
	}

	render() {
		return true
	}
}

export default IndexPage
